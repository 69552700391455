/* eslint-disable no-nested-ternary */
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { connect } from 'react-redux';
import { hideModal } from '../../actions/modalActions';

function Modal(props) {
  var { modal: component, hideModal } = props;
  return (
    <Dialog
      id="modal-container"
      scroll="paper"
      maxWidth={'100vw'}
      open={!!component}
      onClose={hideModal}
    >
      {component}
    </Dialog>
  );
}

const mapStateToProps = (state) => ({ ...state.modalReducer });
const mapDispatchToProps = { hideModal };

export default connect(mapStateToProps, mapDispatchToProps)(Modal);