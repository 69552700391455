module.exports = (theme) => `
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
border: none !important;
-webkit-text-fill-color: inherit !important;
-webkit-box-shadow: 0 0 0px 1000px ${theme['text-field-background']} inset;
transition: ${theme['text-field-background']} 5000s ease-in-out 0s;
}
display: flex;
flex-direction: column;
width: 100vw;
height: 100%;
background-color: ${theme['app-background-color']};
`;