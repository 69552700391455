import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { withTheme } from 'styled-components';
import AppProvider, { AppContext } from '../AppProvider';
import Spinner from '../Spinner';
import Modal from '../Modal';
import style from './style';

import '../../style.css';

function Layout(props) {
  const { theme } = props;
  const { state:{ isLoading } } = useContext(AppContext);

  useEffect(() => {
    document.body.style.backgroundColor = theme['app-background-color'];
  }, [theme]);

  const appContainerCss = style(theme);

  return (
    <HelmetProvider>
      <AppProvider>
        <Helmet>
          <title>SubDrop</title>
          <meta content="Subscription service for drops" name="description" />
          <meta content="subdrop" name="keywords" />
          <meta
            name="viewport"
            content={'width=device-width,height=device-height, initial-scale=1, maximum-scale=1, shrink-to-fit=no, user-scalable=no'}
          />
          <script src="https://js.stripe.com/v3/"></script>
          <link rel="stylesheet" href="https://use.typekit.net/ptd7esc.css"></link>
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
          <meta charSet="utf-8" />
          <meta httpEquiv="x-ua-compatible" content="ie=edge" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png?v=693gzx8oqL" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png?v=693gzx8oqL" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png?v=693gzx8oqL" />
          <link rel="manifest" href="/site.webmanifest?v=693gzx8oqL" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg?v=693gzx8oqL" color="#5bbad5" />
          <link rel="shortcut icon" href="/favicon.ico?v=693gzx8oqL" />
          <meta name="apple-mobile-web-app-title" content="SubDrop" />
          <meta name="application-name" content="SubDrop" />
          <meta name="msapplication-TileColor" content="#da532c" />
          <meta name="theme-color" content="#ffffff"></meta>
        </Helmet>
        <div id="app-container" css={appContainerCss}>
          <Modal />
          {isLoading && <Spinner />}
          {props.children}
        </div>
      </AppProvider>
    </HelmetProvider>
  );
}

export default withTheme(Layout);

Layout.propTypes = { children: PropTypes.node.isRequired };