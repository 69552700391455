import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { logout } from '../../actions/userActions';
import './hamburger.css';
import Logo from '../Logo';
import { navigate } from 'gatsby';

export const Container = styled.div`
`;

export const Body = styled.div`
  opacity: ${props => props.active ? '1' : '0'};
  transition: opacity .3s ease-in-out;
  width: 100%;
  height: 100vh;
  background-color: white;
  z-index: 2;
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: ${props => props.active ? 'all' : 'none'};
  display: flex;
  flex-direction: column;
`;

const MenuItem = styled.div`
  font-family: Manrope;
  font-style: normal;
  font-weight: 800;
  font-size: 35px;
  line-height: 48px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #000000;
  -webkit-appearance: none;
  text-decoration: none;
  :visited {
    text-decoration: none;
    color: initial;
  }
`;

function Menu({ user, logout }) {
  const [active, setActive] = useState(false);
  const items = [
    { name:'Home', link: '/' },
    {
      name: user ? 'Logout' : 'Sign In', link: user ? () => {
        setActive(false);
        logout();
      } : '/login',
    },
    { name:'Drops', link: '/drops' },
    { name:'Terms Of Service', link: '/terms-of-service' },
    { name:'Contact', link: 'mailto:subdropla@gmail.clm' },
  ];
  return (
    <>
      <div onClick={() => setActive(!active)} className={`hamburger hamburger--spring ${active ? 'is-active' : ''}`}>
        <span className="hamburger-box">
          <span className="hamburger-inner"></span>
        </span>
      </div>
      <Body active={active}>
        <Logo style={{ marginTop: 52, marginBottom: 80 }} />
        {items.map(({ name, link }) => (<>
          <MenuItem onClick={typeof(link) === 'function' ? link : () => {
            setActive(!active);
            navigate(link);
          }} key={name}
          >{name}</MenuItem>
          <br />
        </>
        ) )}
      </Body>
    </>
  );
}

const mapDispatchToProps = { logout };

const mapStateToProps = (state) => ({ ...state.userReducer, mapDispatchToProps });

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
