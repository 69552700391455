import React from 'react';
import { navigate } from 'gatsby';
import Icon from './logo.svg';
import WhiteIcon from './logo_white.svg';

function goHome() {
  navigate('/');
}

function Logo({ style, white }) {
  if (typeof(white) !== 'undefined') {
    return (
      <img onClick={goHome} style={{ ...style, height: 18 }} src={WhiteIcon} />
    );
  }
  return (
    <img onClick={goHome} style={{ ...style, height: 18 }} src={Icon} />
  );
}

export default Logo;
