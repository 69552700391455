import React from 'react';
import styled from 'styled-components';

const Animation = styled.div`
  font-size: 10px;
  margin: auto;
  text-indent: -9999em;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: ${props => props.color};
  background: ${props => `-moz-linear-gradient(left, ${props.color} 10%, rgba(255, 255, 255, 0) 42%)`};
  background: ${props => `-webkit-linear-gradient(left, ${props.color} 10%, rgba(255, 255, 255, 0) 42%)`};
  background: ${props => `-o-linear-gradient(left, ${props.color} 10%, rgba(255, 255, 255, 0) 42%)`};
  background: ${props => `-ms-linear-gradient(left, ${props.color} 10%, rgba(255, 255, 255, 0) 42%)`};
  background: ${props => `linear-gradient(to right, ${props.color} 10%, rgba(255, 255, 255, 0) 42%)`};
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
:before {
  width: 50%;
  height: 50%;
  background: ${props => props.color};
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}
:after {
  background: ${props => props.secondaryColor};
  width: 90%;
  height: 90%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

`;

const Container = styled.div`
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 9999;
  height: 665px;
  display: ${props => props.show ? 'flex' : 'none'};
  flex-direction: column;
`;

function Spinner({
  color = '#000000', secondaryColor='#FFFFFF', show = true,
}) {
  return (
    <Container show={show}>
      <Animation color={color} secondaryColor={secondaryColor} />
    </Container>
  );
}

export default Spinner;
